@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}


::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

html{
	position: relative;
	overflow-x: hidden;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: $textColor;
	background-color: #fff;
	// position: relative;
	overflow-x: hidden;
	// -webkit-text-stroke-width: 0.1px;
}

img,
svg{
	width: 100%;
	height: auto;
}

.inner{
	position: relative;
	z-index: 1;
	max-width: 1000px;
	margin: 0 auto;
	padding: 0 15px;
}

.pc{display: none;}
.smt{display: block;}

ul{
	letter-spacing: -.4em;
	li{
		letter-spacing: normal;
	}
}

h1,h2,h3,h4,h5,h6{
	line-height: 1.6;
	span{
		display: inline-block;
	}
}

/* font-family */
.f_mincho{
	font-family: $fontMincho;
	font-weight: bold;
}
.highlight{
	font-family: $fontJa;
	font-weight: 600;
	span{
		display: inline;
		background: linear-gradient(transparent 60%, #F6E821 60%, #F6E821 85%, transparent 86%, transparent 100%);
	}
}




header{
	padding: 20px 15px;
}
h1{
	line-height: 0;
	width: 80%;
	max-width: 300px;
	margin: auto;
}

main{
	p{
		span{
			display: inline-block;
		}
	}
}

#main-view{
	line-height: 0;
	overflow: hidden;
	position: relative;
	max-width: 1280px;
	margin: auto;
	img:first-of-type{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
	}
	img:last-of-type{
		display: block;
		position: relative;
		z-index: 1;
		width: 90%;
		max-width: 856px;
		margin: auto;
	}
}

.sec{
	text-align: center;
}

.sec01{
	h2{
		font-size: 20px;
		padding: 3% 0;
	}
	p{
		font-size: 16px;
	}
}


.sec02{
	margin: 10% 0;
	background: url(../img/sec02/bg.png);
	background-size: 640px auto;
	.inner{
		max-width: 1280px;
		padding: 0;
	}
	.illust{
		letter-spacing: -.4em;
		span{
			position: relative;
			letter-spacing: normal;
			line-height: 0;
			display: inline-block;
			width: 12.5%;
			max-width: 160px;
			transform: scale(1.19);
		}
	}

	.sec-head{
		position: relative;
		max-width: 1075px;
		margin: auto;
		transform: translateY(-50%);
		h3{
			line-height: 0;
			width: 75%;
			max-width: 550px;
			margin: auto;
		}
		.illust{
			span.i1{
				img{
					animation: pendulum15 1s linear infinite;
				}
			}
			span.i2{
				top: -10px;
				img{
					animation: pendulum15 2s linear infinite;
				}
			}
			span.i3{
				top: -10px;
				img{
					animation: pendulum15 1.5s linear infinite;
				}
			}
			span.i4{
				img{
					animation: pendulum15 2.5s linear infinite;
				}
			}
		}
		.illust-left,
		.illust-right{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			transform: translateY(-15%);
			box-sizing: border-box;
		}
		.illust-left{
			text-align: left;
			padding-left: 10px;
		}
		.illust-right{
			text-align: right;
		}
	}

	.sec-body{
		margin: 2% 0 0;
		padding: 0 15px;
		p{
			font-size: 15px;
			font-family: $fontJa;
			font-weight: 600;
		}
	}

	.sec-foot{
		position: relative;
		.illust{
			transform: translateY(50%);
			span.i1{
				img{
					animation: pendulum15 1s linear infinite;
				}
			}
			span.i2{
				img{
					animation: pendulum15 2s linear infinite;
				}
			}
			span.i3{
				img{
					animation: pendulum15 1.5s linear infinite;
				}
			}
			span.i4{
				img{
					animation: pendulum15 2.5s linear infinite;
				}
			}
			span.i5{
				img{
					animation: pendulum15 1s linear infinite;
				}
			}
			span.i6{
				img{
					animation: pendulum15 2s linear infinite;
				}
			}
			span.i7{
				img{
					animation: pendulum15 1.5s linear infinite;
				}
			}
			span.i8{
				img{
					animation: pendulum15 2.5s linear infinite;
				}
			}
		}
	}
}

@keyframes pendulum15 {
	0%{transform: rotate(15deg);}
	50%{transform: rotate(15deg);}
	51%{transform: rotate(-15deg);}
	100%{transform: rotate(-15deg);}
}

.sec03{
	.sec-head{
		a{
			display: block;
			width: 90%;
			max-width: 735px;
			margin: auto;
		}
		p.highlight{
			font-size: 18px;
			line-height: 1.6;
			margin: 4% 0;
			span{
				display: inline-block;
			}
		}
	}
	.sec-body{
		position: relative;
		.sec-body-in{
			position: relative;
			max-width: 1140px;
			margin: auto;
			.inner{
				z-index: 2;
			}
			.bg-img{
				position: absolute;
				bottom: 10%;
				left: -5%;
				z-index: 1;
				width: 79%;
				height: auto;
			}
		}
	}
	.sec-body:before{
		content: '';
		display: block;
		position: absolute;
		top: 6%;
		left: 0;
		width: 100%;
		height: 62%;
		max-height: 512px;
		background: url(../img/sec03/bg01.png);
		background-size: auto 100%;
	}
	.sec-foot{
		position: relative;
		margin: 5% 0 10%;
		.inner{
			line-height: 0;
			max-width: 970px;
			z-index: 1;
		}
	}
	.sec-foot:before{
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 80%;
		max-height: 279px;
		background: url(../img/sec03/bg02.png);
		background-size: auto 100%;
		transform: translateY(-50%);
	}
}

.sec04{
	position: relative;
	.sec-head,
	.sec-body{
		position: relative;
		z-index: 1;
	}
	.sec-head{
		max-width: 1280px;
		margin: auto;
		.sec-img{
			line-height: 0;
		}
		h3{
			max-width: 883px;
			margin: auto;
			transform: translateY(-50%);
		}
	}
	.sec-body{
		margin: -5% 0 0;
		padding: 0 0 6%;
		.inner{
			line-height: 0;
			max-width: 800px;

		}
	}
}
.sec04:before{
	content: '';
	display: block;
	width: 100%;
	height: calc(100% - 11.5%);
	position: absolute;
	top: 11.5%;
	left: 0;
	background: url(../img/sec04/bg.png);
	background-size: 640px auto;
}

.sec05{
	padding: 5% 0;
	h3{
		font-size: 18px;
		line-height: 1.6;
		padding: 0 0 5%;
		span{
			display: inline-block;
		}
	}
}

form{
	input,
	select,
	textarea{
		font-size: 15px;
		font-family: $fontJa;
		font-weight: 500;
		width: 100%;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
	}
	input,
	select{
		height: 40px;
	}
	input {
		vertical-align: middle;
	}
	input[type=text]{
		position: relative;
		top: -2px;
	}
	input[type=radio],
	input[type=image],
	input[type=checkbox]{
		border: none;
		width: auto;
		height: auto;
		vertical-align: middle;
		margin: 0;
	}
	input[type=radio]{
		position: relative;
		top: -1px;
		margin-right: 2px;
	}
	input.type_s{
		width: 20%;
		max-width: 125px;
	}

	select{
		vertical-align: middle;
		width: auto;
		font-family: $fontBase;
		font-weight: bold;
	}
	textarea {
		
	}
	p.error{
		color: #f00;
		margin-top: 10px !important;
		text-align: center;
		font-size: 15px !important;
		font-family: $fontBase !important;
		font-weight: normal !important;
		letter-spacing: normal !important;
	}
}
#introduction{
	p.big.error{
		color: #f00;
		font-size: 16px;
		font-family: $fontJa;
		font-weight: 600;
		padding-bottom: 3%;
	}
}
#question{
	.que-head{
		.hotel{
			position: relative;
			display: inline-block;
			width: 43%;
			max-width: 367px;
			transition: all .3s;
			input {
				cursor: pointer;
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				width: 100%;  
				height: 100%;
			}
			label{
				padding: 15% 0;
				i{
					opacity: 0;
				}
			}
			label.kurume {
				display: block;
				background: url(../svg/sec05/btn01.svg) no-repeat center;
				background-size: 100% auto;
			}
			label.saga {
				display: block;
				background: url(../svg/sec05/btn02.svg) no-repeat center;
				background-size: 100% auto;
			}
		}
		span{
			display: inline-block;
			font-size: 16px;
			font-weight: bold;
			padding: 0 3%;
		}
		.hotel:not(.on){
			opacity: .5;
			transform: scale(0.9);
		}
	}
	.que-body{
		color: #534741;
		margin: 12% -15px 0;
		padding: 0 0 5%;
		background: url(../img/sec05/bg.png);
		em{
			color: #E71C24;
			font-size: $fontSize;
			font-family: $fontBase;
			font-weight: normal;
			vertical-align: middle;
		}
		h4{
			transform: translateY(-30%);
			span{
				line-height: 0;
				display: inline-block;
				width: 60%;
				max-width: 403px;
			}
			small{
				display: block;
			}
		}
	}
}

#que-elem{
	display: inline-block;
	padding: 0 15px;
	max-width: 740px;
	margin: auto;
	dl{
		dt{
			position: relative;
			margin: 5% 0 0;
			em{
				vertical-align: middle;
				margin-right: 3px;
			}
			.que{
				display: block;
				vertical-align: middle;
				font-size: 16px;
				font-family: $fontJa;
				font-weight: 600;
				line-height: 1.6;
			}
		}
		dd{
			font-size: 16px;
			font-family: $fontJa;
			font-weight: 600;
			padding: 2% 0 20px;
			select{
				margin: 0 3px 0 6px;
			}
			small{
				font-size: .9em;
				vertical-align: bottom;
				position: relative;
				top: 5px;
			}
		}
	}
	dl.que-first{
		dt{
			.ico{
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(-40%);
				img{
					vertical-align: middle;
					width: 30px;
				}
			}
			.que{
				text-align: left;
				margin-left: 55px;
				padding: 0 5px 10px;
				background: url(../img/sec05/bg_que.png) repeat-x left bottom;
				background-size: auto 5px;
			}
		}
		dd{
			letter-spacing: -.4em;
			margin-left: 55px;
			text-align: left;
			color: #000;
			label{
				cursor: pointer;
				position: relative;
				letter-spacing: normal;
				display: inline-block;
				width: 50%;
				padding: 0 0 0 20px;
				box-sizing: border-box;
				input{
					display: none;
				}
			}
			label:before{
				content: "";
				position: absolute;
				top: 50%;
				left: 0;
				width: 15px;
				height: 15px;
				margin-top: -6px;
				background: #fff;
				border: 1px solid #736357;
				border-radius: 50%;
			}
			label.on:after{
				position: absolute;
				content: "";
				top: 50%;
				left: 4px;
				width: 9px;
				height: 9px;
				margin-top: -2px;
				border-radius: 50%;
				background: #CE145A;
			}
		}
	}
	p{
		font-size: 16px;
		font-family: $fontJa;
		font-weight: 500;
		line-height: 1.6;
		margin: 10px 0;
		span{
			display: inline-block;
		}
		small{
			font-size: .9em;
		}
	}
	dl.que-second{
		dt{
			.que{
				display: inline-block;
			}
		}
		dd{
			padding: 3% 0 20px;
			label{
				margin: 0 3%;
			}
		}
	}

	.que-third{
		text-align: left;
		table{
			width: 100%;
			font-size: 16px;
			font-family: $fontJa;
			font-weight: 500;
			tr{
				display: block;
				width: 100%;
				th,
				td{
					display: block;
					width: 100%;
				}
				th{
					white-space: nowrap;
					position: relative;
					margin-top: 10px;
					div{
						position: relative;
					}
					em{
						position: absolute;
						top: 50%;
						left: 0;
						transform: translateY(-50%);
					}
					span{
						display: block;
						color: #fff;
						background-color: #534741;
						margin-left: 20px;
						width: calc(100% - 20px);
						padding: 5px 10px;
						box-sizing: border-box;
					}
				}
				td{
					padding: 15px 0 15px 20px;
					box-sizing: border-box;
				}
				td.col2{
					label{
						position: relative;
						display: inline-block;
						width: 48%;
						b{
							position: absolute;
							top: 50%;
							left: 0;
							transform: translateY(-55%);
						}
						input{
							margin-left: 22px;
							width: calc(100% - 22px);
						}
					}
					label:nth-of-type(2){
						float: right;
					}
				}
			}
		}
		p.error{
			text-align: left;
		}
	}
}

.que-foot{
	.box{
		letter-spacing: 0;
		line-height: 1.8;
		max-width: 880px;
		margin: 40px auto 0;
		border: 1px solid #1A1311;
		padding: 0 5% 30px;
		box-sizing: border-box;
		h5{
			text-align: center;
			display: inline-block;
			margin: auto;
			font-size: 16px;
			letter-spacing: .5em;
			background-color: #fff;
			padding: 0 3%;
			transform: translateY(-50%);
		}
		.scroll-y{
			text-align: left;
			height: 220px;
			overflow: auto;
			font-size: 13px;
			ul{
				li{
					position: relative;
					padding-left: 1em;
				}
				li:before{
					content: '・';
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			dl{
				dt{
					margin-top: 15px;
				}
				dd{
					ol{
						li{
							position: relative;
							padding-left: 1.5em;
							b{
								position: absolute;
								top: 0;
								left: 0;
							}
						}

					}
				}
			}
		}
	}
	.checks{
		font-size: 15px;
		font-family: $fontJa;
		font-weight: 600;
		margin: 40px auto;
		label{
			cursor: pointer;
			position: relative;
			display: inline-block;
			margin: 10px 0 0;
			padding: 0 0 0 22px;
			color: #CE145A;
			input{
				display: none;
			}
		}
		label:before{
			content: "";
			position: absolute;
			top: 14px;
			left: 0;
			width: 15px;
			height: 15px;
			margin-top: -6px;
			background: #fff;
			border: 1px solid #736357;
			border-radius: 50%;
		}
		label.on:after{
			position: absolute;
			content: "";
			top: 14px;
			left: 4px;
			width: 9px;
			height: 9px;
			margin-top: -2px;
			border-radius: 50%;
			background: #CE145A;
		}
	}
	
}

#notice{
	width: 100%;
	max-width: 640px;
	margin: 0 auto 40px;
	color: #f00;
	padding: 20px;
	border: 3px solid #f00;
	box-sizing: border-box;
	border-radius: 5px;
	p{
		span{
			display: inline;
		}
	}
	strong{
		display: block;
		font-size: 24px;
		font-weight: bold;
	}
}

.btns{
	max-width: 717px;
	margin: auto;
	button{
		cursor: pointer;
		transition: all .3s;
	}
	button:hover{
		opacity: .7;
	}
	.col2{
		margin: 0 -2%;
		button{
			display: inline-block;
			width: 46%;
			max-width: 348px;
			margin: 0 2%;
		}
	}
}

footer{

}
.foot-head{
	line-height: 0;
	overflow: hidden;
	position: relative;
	max-width: 1280px;
	margin: auto;
}
.foot-body{
	line-height: 0;
	width: 80%;
	max-width: 400px;
	margin: auto;
	padding: 8% 0 0;
}
.foot-foot{
	padding: 8% 0;
	ul{
		text-align: center;
		li{
			display: inline-block;
			width: 100%;
			max-width: 405px;
			a{
				display: block;
				line-height: 0;
				margin: 0 0 20px;
			}
		}
	}
}
.copyright{
	text-align: center;
	color: #fff;
	font-size: 12px;
	line-height: 1.6;
	letter-spacing: .5px;
	padding: 10px;
	background-color: #E680AE;
	small{
		display: inline-block;
	}
}



/* confirm */
#confirm,
#finish{
	border-top: 1px solid #ccc;
	.sec05{
		padding: 0 0 5%;
	}
	.notes{
		text-align: center;
		color: #ab003e;
		font-size: 16px;
		font-family: $fontJa;
		font-weight: 600;
		line-height: 1.8;
		margin: 8% 0;
		span{
			display: inline-block;
		}
	}
	#question{
		.que-head{
			.hotel{
				width: 100%;
			}
			.hotel:not(.on){
				opacity: 1;
				transform: scale(1);
			}
		}
		.que-body{
			padding: 5% 0;
			margin: 10% -15px 0;
		}
	}
	#que-elem{
		display: block;
		dl{
			dt{
				// color: #996c33;
				opacity: .4;
			}
			dd{
				padding: 2% 0 20px;
				small {
					top: 0;
				}
			}
		}
		p{
			span{
				// color: #996c33;
				opacity: .4;
			}
		}
		dl.que-first{
			dt{
				.que {
					margin-left: 35px;
				}
			}
			dd{
				letter-spacing: normal;
				margin-left: 35px;
				padding-left: 5px;
				strong{
					font-size: 1.2em;
				}
			}
		}
		.comment{
			text-align: left;
			font-size: 15px;
			font-family: $fontJa;
			font-weight: 500;
			line-height: 1.6;
			padding: 15px;
			background-color: #fff;
			border: 1px solid #ccc;
		}
		dl.que-second{
			dd{
				padding: 0;
				strong{
					font-size: 1.2em;
					label{
						display: inline-block;
					}
				}
			}
		}
		.que-third{
			table{
				tr{
					th{
						span{
							width: 100%;
							margin-left: 0;
							// background-color: #996c33;
							opacity: .4;
						}
					}
					td{
						padding: 10px 0;
					}
				}
			}
		}
	}
	.que-foot{
		.checks{
			p{
				img{
					width: 30px;
					vertical-align: middle;
					margin-right: 5px;
				}
				img + span{
					vertical-align: middle;
				}
				span{
					color: #CE145A;
				}
			}
		}
	}

}

#finish{
	.notes{
		color: #000;
		strong{
			display: block;
			font-size: 1.2em;
			font-weight: 800;
			margin: 0 0 10px;
		}
	}
}

@media screen and (min-width: 600px) {
	.sec01{
		h2{
			font-size: 30px;

		}
		p{
			font-size: 20px;
			span{
				display: inline-block;
			}
		}
	}

	.sec02{
		.sec-body{
			p{
				font-size: 18px;
				span{
					display: block;
				}
			}
		}
	}

	.sec03{
		.sec-head{
			p.highlight{
				font-size: 24px;
				span{
					display: inline;
				}
			}
		}
	}

	.sec05{
		h3{
			font-size: 24px;
			span{
				display: inline;
			}
		}
	}

	form{
		input,
		select,
		textarea{
			font-size: 17px;
		}
		input,
		select{
			height: 45px;
		}
	}
	#introduction{
		p.big.error{
			font-size: 18px;
		}
	}
	#question{
		.que-head{
			span{
				font-size: 26px;
			}
		}
	}

	#que-elem{
		dl{
			dt{
				.que{
					font-size: 21px;
				}
			}
			dd{
				font-size: 18px;
			}
		}
		dl.que-first{
			dt{
				.ico{
					img{
						width: 36px;
					}
				}
				.que{
					margin-left: 61px;
				}
			}
			dd{
				text-align: center;
				margin-left: 61px;
				label{
					width: auto;
					padding: 0 20px;
				}
			}
		}
		p{
			font-size: 18px;
			margin: 15px 0;
		}
		.que-third{
			table{
				font-size: 18px;
				tr{
					display: table-row;
					width: auto;
					th,
					td{
						display: table-cell;
						width: auto;
					}
					th{
						width: 160px;
						span{
							text-align: center;
							font-size: .8em;
							padding: 7px 15px;
						}
					}
					td{
						padding: 10px 0 10px 25px;
					}
					td.col2{
						label{
							input{
								margin-left: 25px;
								width: calc(100% - 25px);
							}
						}
					}
				}
			}
		}
	}
	.que-foot{
		.box{
			margin: 55px auto 0;
			h5{
				font-size: 18px;
			}
		}
		.checks{
			font-size: 16px;
		}
	}

	#notice{
		margin: 0 auto 60px;
		padding: 20px;
		p{
			span{
				display: inline-block;
			}
		}
		strong{
			font-size: 28px;
		}
	}

	.foot-body{
		padding: 5% 0 0;
	}
	.foot-foot{
		padding: 5% 0;
		ul{
			li{
				width: 44%;
				margin: 0 3%;
			}
		}
	}

/* confirm */
	#confirm{
		.notes{
			font-size: 18px;
			margin: 5% 0;
		}
		#que-elem{
			dl.que-first{
				dt{
					.que {
						margin-left: 41px;
					}
				}
				dd{
					margin-left: 41px;
				}
			}
			.comment{
				font-size: 17px;
				padding: 15px 20px;
			}
			.que-third{
				table{
					tr{
						th{
							// width: 200px;
						}
						td{
							padding: 10px 0 10px 25px;
					
						}
					}
				}
			}
		}
	}

	#finish{
		.notes{
			strong{
				font-size: 1.5em;
			}
		}
	}

}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}
	
	.sec01{
		h2{
			font-size: 40px;
		}
		p{
			font-size: 24px;
		}
	}

	.sec02{
		background: url(../img/sec02/bg.png);
		background-size: 1279px auto;
		.sec-body{
			p{
				font-size: 20px;
			}
		}
	}

	.sec03{
		.sec-head{
			p.highlight{
				font-size: 32px;
			}
		}
	}

	.sec04:before{
		background: url(../img/sec04/bg.png);
		background-size: 1279px auto;
	}

	.sec05{
		h3{
			font-size: 32px;
		}
	}

	form{
		input,
		select,
		textarea{
			font-size: 19px;
		}
		input,
		select{
			height: 50px;
		}
	}
	#introduction{
		p.big.error{
			font-size: 20px;
		}
	}
	#question{
		.que-head{
			span{
				font-size: 36px;
			}
		}
		.que-body{
			margin: 10% 0 0;
			h4{
				transform: translateY(-50%);
				span{
					margin-left: 13.5%;
				}
				small{
					display: inline-block;
					position: relative;
					top: -5px;
					margin-left: 1em;
				}
			}
		}
	}

	#que-elem{
		dl{
			dt{
				.que{
					font-size: 26px;
				}
			}
			dd{
				font-size: 20px;
			}
		}
		dl.que-first{
			dt{
				.ico{
					img{
						width: 42px;
					}
				}
				.que{
					margin-left: 67px;
					background: url(../img/sec05/bg_que.png) repeat-x left bottom;
					background-size: auto 10px;
					padding: 0 5px 15px;
				}
			}
			dd{
				margin-left: 67px;
			}
		}
		p{
			font-size: 20px;
			margin: 30px 0 20px;
		}
		.que-third{
			table{
				font-size: 20px;
				tr{
					th{
						width: 180px;
						span{
							padding: 8px 20px;
						}
					}
					td.col2{
						label{
							input{
								margin-left: 27px;
								width: calc(100% - 27px);
							}
						}
					}
				}
			}
		}
	}
	.que-foot{
		.checks{
			font-size: 17px;
		}
	}

/* confirm */
	#confirm{
		.notes{
			font-size: 20px;
		}
		#question{
			.que-body {
		  	  margin: 6% 0 0;
		  	}
		}
		#que-elem{
			dl.que-first{
				dt{
					.que {
						margin-left: 47px;
					}
				}
				dd{
					margin-left: 47px;
				}
			}
			.comment{
				font-size: 19px;
			}
		}

	}

}


@media screen and (min-width: 1000px) {
	.sec01{
		h2{
			font-size: 50px;
		}
		p{
			font-size: 26px;
		}
	}

	.sec02{
		.sec-body{
			p{
				font-size: 23px;
			}
		}
		.sec-foot{
			.illust{
				span{
					transform: scale(1);
				}
			}
		}
	}

	.sec03{
		.sec-head{
			p.highlight{
				font-size: 43px;
			}
		}
	}

	.sec05{
		h3{
			font-size: 40px;
		}
	}

	form{
		input,
		select,
		textarea{
			font-size: 21px;
		}
	}
	#question{
		.que-head{
			span{
				font-size: 46px;
			}
		}
	}
	#que-elem{
		dl{
			dt{
				.que{
					font-size: 31px;
				}
			}
			dd{
				font-size: 23px;
			}
		}
		dl.que-first{
			dt{
				.ico{
					img{
						width: 48px;
					}
				}
				.que{
					margin-left: 73px;
				}
			}
			dd{
				margin-left: 73px;
			}
		}
		p{
			font-size: 23px;
		}
		.que-third{
			table{
				font-size: 23px;
				tr{
					th{
						width: 200px;
						span{
							padding: 6px 20px;
						}
					}
					td.col2{
						label{
							input{
								margin-left: 31px;
								width: calc(100% - 31px);
							}
						}
					}
				}
			}
		}
	}
	.que-foot{
		.checks{
			font-size: 18px;
			label:before{
				top: 17px;
			}
			label.on:after{
				top: 17px;
			}
		}
	}

/* confirm */
	#confirm{
		.notes{
			font-size: 22px;
		}
		#que-elem{
			dl.que-first{
				dt{
					.que {
						margin-left: 53px;
					}
				}
				dd{
					margin-left: 53px;
				}
			}
			.comment{
				font-size: 21px;
			}
		}
	}

}


/* max */
@media screen and (min-width: 1280px) {
	
	.sec02{
		margin: 115px 0;
	}

	.sec03{
		.sec-foot{
			margin: 50px 0 100px;
		}
	}
	.sec04{
		.sec-body{
			margin: -45px 0 0;
			padding: 0 0 80px;
		}
	}
	.sec05{
		padding: 70px 0;
	}

	.foot-body{
		padding: 40px 0 0;
	}
	.foot-foot{
		padding: 40px 0;
	}

	#confirm{
		.sec05{
			padding: 0 0 70px;
		}
	}

}
