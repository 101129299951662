@charset "UTF-8";
/* colors */
/* font-family */
/* easing */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, input, textarea, button, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: inherit;
  font-style: normal;
  vertical-align: baseline;
}

body {
  -webkit-text-size-adjust: 100%;
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ul, ol {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input, select {
  vertical-align: middle;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

img {
  -ms-interpolation-mode: bicubic;
}

::selection {
  background: #000;
  color: #fff;
}

::-moz-selection {
  background: #000;
  color: #fff;
}

html {
  position: relative;
  overflow-x: hidden;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  line-height: 2;
  letter-spacing: .1em;
  color: #000;
  background-color: #fff;
  overflow-x: hidden;
}

img,
svg {
  width: 100%;
  height: auto;
}

.inner {
  position: relative;
  z-index: 1;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
}

.pc {
  display: none;
}

.smt {
  display: block;
}

ul {
  letter-spacing: -.4em;
}

ul li {
  letter-spacing: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.6;
}

h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
  display: inline-block;
}

/* font-family */
.f_mincho {
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
  font-weight: bold;
}

.highlight {
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

.highlight span {
  display: inline;
  background: linear-gradient(transparent 60%, #F6E821 60%, #F6E821 85%, transparent 86%, transparent 100%);
}

header {
  padding: 20px 15px;
}

h1 {
  line-height: 0;
  width: 80%;
  max-width: 300px;
  margin: auto;
}

main p span {
  display: inline-block;
}

#main-view {
  line-height: 0;
  overflow: hidden;
  position: relative;
  max-width: 1280px;
  margin: auto;
}

#main-view img:first-of-type {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

#main-view img:last-of-type {
  display: block;
  position: relative;
  z-index: 1;
  width: 90%;
  max-width: 856px;
  margin: auto;
}

.sec {
  text-align: center;
}

.sec01 h2 {
  font-size: 20px;
  padding: 3% 0;
}

.sec01 p {
  font-size: 16px;
}

.sec02 {
  margin: 10% 0;
  background: url(../img/sec02/bg.png);
  background-size: 640px auto;
}

.sec02 .inner {
  max-width: 1280px;
  padding: 0;
}

.sec02 .illust {
  letter-spacing: -.4em;
}

.sec02 .illust span {
  position: relative;
  letter-spacing: normal;
  line-height: 0;
  display: inline-block;
  width: 12.5%;
  max-width: 160px;
  transform: scale(1.19);
}

.sec02 .sec-head {
  position: relative;
  max-width: 1075px;
  margin: auto;
  transform: translateY(-50%);
}

.sec02 .sec-head h3 {
  line-height: 0;
  width: 75%;
  max-width: 550px;
  margin: auto;
}

.sec02 .sec-head .illust span.i1 img {
  animation: pendulum15 1s linear infinite;
}

.sec02 .sec-head .illust span.i2 {
  top: -10px;
}

.sec02 .sec-head .illust span.i2 img {
  animation: pendulum15 2s linear infinite;
}

.sec02 .sec-head .illust span.i3 {
  top: -10px;
}

.sec02 .sec-head .illust span.i3 img {
  animation: pendulum15 1.5s linear infinite;
}

.sec02 .sec-head .illust span.i4 img {
  animation: pendulum15 2.5s linear infinite;
}

.sec02 .sec-head .illust-left,
.sec02 .sec-head .illust-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-15%);
  box-sizing: border-box;
}

.sec02 .sec-head .illust-left {
  text-align: left;
  padding-left: 10px;
}

.sec02 .sec-head .illust-right {
  text-align: right;
}

.sec02 .sec-body {
  margin: 2% 0 0;
  padding: 0 15px;
}

.sec02 .sec-body p {
  font-size: 15px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

.sec02 .sec-foot {
  position: relative;
}

.sec02 .sec-foot .illust {
  transform: translateY(50%);
}

.sec02 .sec-foot .illust span.i1 img {
  animation: pendulum15 1s linear infinite;
}

.sec02 .sec-foot .illust span.i2 img {
  animation: pendulum15 2s linear infinite;
}

.sec02 .sec-foot .illust span.i3 img {
  animation: pendulum15 1.5s linear infinite;
}

.sec02 .sec-foot .illust span.i4 img {
  animation: pendulum15 2.5s linear infinite;
}

.sec02 .sec-foot .illust span.i5 img {
  animation: pendulum15 1s linear infinite;
}

.sec02 .sec-foot .illust span.i6 img {
  animation: pendulum15 2s linear infinite;
}

.sec02 .sec-foot .illust span.i7 img {
  animation: pendulum15 1.5s linear infinite;
}

.sec02 .sec-foot .illust span.i8 img {
  animation: pendulum15 2.5s linear infinite;
}

@keyframes pendulum15 {
  0% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(15deg);
  }
  51% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(-15deg);
  }
}

.sec03 .sec-head a {
  display: block;
  width: 90%;
  max-width: 735px;
  margin: auto;
}

.sec03 .sec-head p.highlight {
  font-size: 18px;
  line-height: 1.6;
  margin: 4% 0;
}

.sec03 .sec-head p.highlight span {
  display: inline-block;
}

.sec03 .sec-body {
  position: relative;
}

.sec03 .sec-body .sec-body-in {
  position: relative;
  max-width: 1140px;
  margin: auto;
}

.sec03 .sec-body .sec-body-in .inner {
  z-index: 2;
}

.sec03 .sec-body .sec-body-in .bg-img {
  position: absolute;
  bottom: 10%;
  left: -5%;
  z-index: 1;
  width: 79%;
  height: auto;
}

.sec03 .sec-body:before {
  content: '';
  display: block;
  position: absolute;
  top: 6%;
  left: 0;
  width: 100%;
  height: 62%;
  max-height: 512px;
  background: url(../img/sec03/bg01.png);
  background-size: auto 100%;
}

.sec03 .sec-foot {
  position: relative;
  margin: 5% 0 10%;
}

.sec03 .sec-foot .inner {
  line-height: 0;
  max-width: 970px;
  z-index: 1;
}

.sec03 .sec-foot:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 80%;
  max-height: 279px;
  background: url(../img/sec03/bg02.png);
  background-size: auto 100%;
  transform: translateY(-50%);
}

.sec04 {
  position: relative;
}

.sec04 .sec-head,
.sec04 .sec-body {
  position: relative;
  z-index: 1;
}

.sec04 .sec-head {
  max-width: 1280px;
  margin: auto;
}

.sec04 .sec-head .sec-img {
  line-height: 0;
}

.sec04 .sec-head h3 {
  max-width: 883px;
  margin: auto;
  transform: translateY(-50%);
}

.sec04 .sec-body {
  margin: -5% 0 0;
  padding: 0 0 6%;
}

.sec04 .sec-body .inner {
  line-height: 0;
  max-width: 800px;
}

.sec04:before {
  content: '';
  display: block;
  width: 100%;
  height: calc(100% - 11.5%);
  position: absolute;
  top: 11.5%;
  left: 0;
  background: url(../img/sec04/bg.png);
  background-size: 640px auto;
}

.sec05 {
  padding: 5% 0;
}

.sec05 h3 {
  font-size: 18px;
  line-height: 1.6;
  padding: 0 0 5%;
}

.sec05 h3 span {
  display: inline-block;
}

form input,
form select,
form textarea {
  font-size: 15px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 500;
  width: 100%;
  border: 1px solid #dcdcdc;
  box-sizing: border-box;
}

form input,
form select {
  height: 40px;
}

form input {
  vertical-align: middle;
}

form input[type=text] {
  position: relative;
  top: -2px;
}

form input[type=radio],
form input[type=image],
form input[type=checkbox] {
  border: none;
  width: auto;
  height: auto;
  vertical-align: middle;
  margin: 0;
}

form input[type=radio] {
  position: relative;
  top: -1px;
  margin-right: 2px;
}

form input.type_s {
  width: 20%;
  max-width: 125px;
}

form select {
  vertical-align: middle;
  width: auto;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-weight: bold;
}

form p.error {
  color: #f00;
  margin-top: 10px !important;
  text-align: center;
  font-size: 15px !important;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif !important;
  font-weight: normal !important;
  letter-spacing: normal !important;
}

#introduction p.big.error {
  color: #f00;
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  padding-bottom: 3%;
}

#question .que-head .hotel {
  position: relative;
  display: inline-block;
  width: 43%;
  max-width: 367px;
  transition: all .3s;
}

#question .que-head .hotel input {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

#question .que-head .hotel label {
  padding: 15% 0;
}

#question .que-head .hotel label i {
  opacity: 0;
}

#question .que-head .hotel label.kurume {
  display: block;
  background: url(../svg/sec05/btn01.svg) no-repeat center;
  background-size: 100% auto;
}

#question .que-head .hotel label.saga {
  display: block;
  background: url(../svg/sec05/btn02.svg) no-repeat center;
  background-size: 100% auto;
}

#question .que-head span {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  padding: 0 3%;
}

#question .que-head .hotel:not(.on) {
  opacity: .5;
  transform: scale(0.9);
}

#question .que-body {
  color: #534741;
  margin: 12% -15px 0;
  padding: 0 0 5%;
  background: url(../img/sec05/bg.png);
}

#question .que-body em {
  color: #E71C24;
  font-size: 14px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-weight: normal;
  vertical-align: middle;
}

#question .que-body h4 {
  transform: translateY(-30%);
}

#question .que-body h4 span {
  line-height: 0;
  display: inline-block;
  width: 60%;
  max-width: 403px;
}

#question .que-body h4 small {
  display: block;
}

#que-elem {
  display: inline-block;
  padding: 0 15px;
  max-width: 740px;
  margin: auto;
}

#que-elem dl dt {
  position: relative;
  margin: 5% 0 0;
}

#que-elem dl dt em {
  vertical-align: middle;
  margin-right: 3px;
}

#que-elem dl dt .que {
  display: block;
  vertical-align: middle;
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  line-height: 1.6;
}

#que-elem dl dd {
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  padding: 2% 0 20px;
}

#que-elem dl dd select {
  margin: 0 3px 0 6px;
}

#que-elem dl dd small {
  font-size: .9em;
  vertical-align: bottom;
  position: relative;
  top: 5px;
}

#que-elem dl.que-first dt .ico {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-40%);
}

#que-elem dl.que-first dt .ico img {
  vertical-align: middle;
  width: 30px;
}

#que-elem dl.que-first dt .que {
  text-align: left;
  margin-left: 55px;
  padding: 0 5px 10px;
  background: url(../img/sec05/bg_que.png) repeat-x left bottom;
  background-size: auto 5px;
}

#que-elem dl.que-first dd {
  letter-spacing: -.4em;
  margin-left: 55px;
  text-align: left;
  color: #000;
}

#que-elem dl.que-first dd label {
  cursor: pointer;
  position: relative;
  letter-spacing: normal;
  display: inline-block;
  width: 50%;
  padding: 0 0 0 20px;
  box-sizing: border-box;
}

#que-elem dl.que-first dd label input {
  display: none;
}

#que-elem dl.que-first dd label:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 15px;
  margin-top: -6px;
  background: #fff;
  border: 1px solid #736357;
  border-radius: 50%;
}

#que-elem dl.que-first dd label.on:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 4px;
  width: 9px;
  height: 9px;
  margin-top: -2px;
  border-radius: 50%;
  background: #CE145A;
}

#que-elem p {
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 500;
  line-height: 1.6;
  margin: 10px 0;
}

#que-elem p span {
  display: inline-block;
}

#que-elem p small {
  font-size: .9em;
}

#que-elem dl.que-second dt .que {
  display: inline-block;
}

#que-elem dl.que-second dd {
  padding: 3% 0 20px;
}

#que-elem dl.que-second dd label {
  margin: 0 3%;
}

#que-elem .que-third {
  text-align: left;
}

#que-elem .que-third table {
  width: 100%;
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 500;
}

#que-elem .que-third table tr {
  display: block;
  width: 100%;
}

#que-elem .que-third table tr th,
#que-elem .que-third table tr td {
  display: block;
  width: 100%;
}

#que-elem .que-third table tr th {
  white-space: nowrap;
  position: relative;
  margin-top: 10px;
}

#que-elem .que-third table tr th div {
  position: relative;
}

#que-elem .que-third table tr th em {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#que-elem .que-third table tr th span {
  display: block;
  color: #fff;
  background-color: #534741;
  margin-left: 20px;
  width: calc(100% - 20px);
  padding: 5px 10px;
  box-sizing: border-box;
}

#que-elem .que-third table tr td {
  padding: 15px 0 15px 20px;
  box-sizing: border-box;
}

#que-elem .que-third table tr td.col2 label {
  position: relative;
  display: inline-block;
  width: 48%;
}

#que-elem .que-third table tr td.col2 label b {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-55%);
}

#que-elem .que-third table tr td.col2 label input {
  margin-left: 22px;
  width: calc(100% - 22px);
}

#que-elem .que-third table tr td.col2 label:nth-of-type(2) {
  float: right;
}

#que-elem .que-third p.error {
  text-align: left;
}

.que-foot .box {
  letter-spacing: 0;
  line-height: 1.8;
  max-width: 880px;
  margin: 40px auto 0;
  border: 1px solid #1A1311;
  padding: 0 5% 30px;
  box-sizing: border-box;
}

.que-foot .box h5 {
  text-align: center;
  display: inline-block;
  margin: auto;
  font-size: 16px;
  letter-spacing: .5em;
  background-color: #fff;
  padding: 0 3%;
  transform: translateY(-50%);
}

.que-foot .box .scroll-y {
  text-align: left;
  height: 220px;
  overflow: auto;
  font-size: 13px;
}

.que-foot .box .scroll-y ul li {
  position: relative;
  padding-left: 1em;
}

.que-foot .box .scroll-y ul li:before {
  content: '・';
  position: absolute;
  top: 0;
  left: 0;
}

.que-foot .box .scroll-y dl dt {
  margin-top: 15px;
}

.que-foot .box .scroll-y dl dd ol li {
  position: relative;
  padding-left: 1.5em;
}

.que-foot .box .scroll-y dl dd ol li b {
  position: absolute;
  top: 0;
  left: 0;
}

.que-foot .checks {
  font-size: 15px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  margin: 40px auto;
}

.que-foot .checks label {
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin: 10px 0 0;
  padding: 0 0 0 22px;
  color: #CE145A;
}

.que-foot .checks label input {
  display: none;
}

.que-foot .checks label:before {
  content: "";
  position: absolute;
  top: 14px;
  left: 0;
  width: 15px;
  height: 15px;
  margin-top: -6px;
  background: #fff;
  border: 1px solid #736357;
  border-radius: 50%;
}

.que-foot .checks label.on:after {
  position: absolute;
  content: "";
  top: 14px;
  left: 4px;
  width: 9px;
  height: 9px;
  margin-top: -2px;
  border-radius: 50%;
  background: #CE145A;
}

#notice {
  width: 100%;
  max-width: 640px;
  margin: 0 auto 40px;
  color: #f00;
  padding: 20px;
  border: 3px solid #f00;
  box-sizing: border-box;
  border-radius: 5px;
}

#notice p span {
  display: inline;
}

#notice strong {
  display: block;
  font-size: 24px;
  font-weight: bold;
}

.btns {
  max-width: 717px;
  margin: auto;
}

.btns button {
  cursor: pointer;
  transition: all .3s;
}

.btns button:hover {
  opacity: .7;
}

.btns .col2 {
  margin: 0 -2%;
}

.btns .col2 button {
  display: inline-block;
  width: 46%;
  max-width: 348px;
  margin: 0 2%;
}

.foot-head {
  line-height: 0;
  overflow: hidden;
  position: relative;
  max-width: 1280px;
  margin: auto;
}

.foot-body {
  line-height: 0;
  width: 80%;
  max-width: 400px;
  margin: auto;
  padding: 8% 0 0;
}

.foot-foot {
  padding: 8% 0;
}

.foot-foot ul {
  text-align: center;
}

.foot-foot ul li {
  display: inline-block;
  width: 100%;
  max-width: 405px;
}

.foot-foot ul li a {
  display: block;
  line-height: 0;
  margin: 0 0 20px;
}

.copyright {
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: .5px;
  padding: 10px;
  background-color: #E680AE;
}

.copyright small {
  display: inline-block;
}

/* confirm */
#confirm,
#finish {
  border-top: 1px solid #ccc;
}

#confirm .sec05,
#finish .sec05 {
  padding: 0 0 5%;
}

#confirm .notes,
#finish .notes {
  text-align: center;
  color: #ab003e;
  font-size: 16px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  line-height: 1.8;
  margin: 8% 0;
}

#confirm .notes span,
#finish .notes span {
  display: inline-block;
}

#confirm #question .que-head .hotel,
#finish #question .que-head .hotel {
  width: 100%;
}

#confirm #question .que-head .hotel:not(.on),
#finish #question .que-head .hotel:not(.on) {
  opacity: 1;
  transform: scale(1);
}

#confirm #question .que-body,
#finish #question .que-body {
  padding: 5% 0;
  margin: 10% -15px 0;
}

#confirm #que-elem,
#finish #que-elem {
  display: block;
}

#confirm #que-elem dl dt,
#finish #que-elem dl dt {
  opacity: .4;
}

#confirm #que-elem dl dd,
#finish #que-elem dl dd {
  padding: 2% 0 20px;
}

#confirm #que-elem dl dd small,
#finish #que-elem dl dd small {
  top: 0;
}

#confirm #que-elem p span,
#finish #que-elem p span {
  opacity: .4;
}

#confirm #que-elem dl.que-first dt .que,
#finish #que-elem dl.que-first dt .que {
  margin-left: 35px;
}

#confirm #que-elem dl.que-first dd,
#finish #que-elem dl.que-first dd {
  letter-spacing: normal;
  margin-left: 35px;
  padding-left: 5px;
}

#confirm #que-elem dl.que-first dd strong,
#finish #que-elem dl.que-first dd strong {
  font-size: 1.2em;
}

#confirm #que-elem .comment,
#finish #que-elem .comment {
  text-align: left;
  font-size: 15px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 500;
  line-height: 1.6;
  padding: 15px;
  background-color: #fff;
  border: 1px solid #ccc;
}

#confirm #que-elem dl.que-second dd,
#finish #que-elem dl.que-second dd {
  padding: 0;
}

#confirm #que-elem dl.que-second dd strong,
#finish #que-elem dl.que-second dd strong {
  font-size: 1.2em;
}

#confirm #que-elem dl.que-second dd strong label,
#finish #que-elem dl.que-second dd strong label {
  display: inline-block;
}

#confirm #que-elem .que-third table tr th span,
#finish #que-elem .que-third table tr th span {
  width: 100%;
  margin-left: 0;
  opacity: .4;
}

#confirm #que-elem .que-third table tr td,
#finish #que-elem .que-third table tr td {
  padding: 10px 0;
}

#confirm .que-foot .checks p img,
#finish .que-foot .checks p img {
  width: 30px;
  vertical-align: middle;
  margin-right: 5px;
}

#confirm .que-foot .checks p img + span,
#finish .que-foot .checks p img + span {
  vertical-align: middle;
}

#confirm .que-foot .checks p span,
#finish .que-foot .checks p span {
  color: #CE145A;
}

#finish .notes {
  color: #000;
}

#finish .notes strong {
  display: block;
  font-size: 1.2em;
  font-weight: 800;
  margin: 0 0 10px;
}

@media screen and (min-width: 600px) {
  .sec01 h2 {
    font-size: 30px;
  }
  .sec01 p {
    font-size: 20px;
  }
  .sec01 p span {
    display: inline-block;
  }
  .sec02 .sec-body p {
    font-size: 18px;
  }
  .sec02 .sec-body p span {
    display: block;
  }
  .sec03 .sec-head p.highlight {
    font-size: 24px;
  }
  .sec03 .sec-head p.highlight span {
    display: inline;
  }
  .sec05 h3 {
    font-size: 24px;
  }
  .sec05 h3 span {
    display: inline;
  }
  form input,
  form select,
  form textarea {
    font-size: 17px;
  }
  form input,
  form select {
    height: 45px;
  }
  #introduction p.big.error {
    font-size: 18px;
  }
  #question .que-head span {
    font-size: 26px;
  }
  #que-elem dl dt .que {
    font-size: 21px;
  }
  #que-elem dl dd {
    font-size: 18px;
  }
  #que-elem dl.que-first dt .ico img {
    width: 36px;
  }
  #que-elem dl.que-first dt .que {
    margin-left: 61px;
  }
  #que-elem dl.que-first dd {
    text-align: center;
    margin-left: 61px;
  }
  #que-elem dl.que-first dd label {
    width: auto;
    padding: 0 20px;
  }
  #que-elem p {
    font-size: 18px;
    margin: 15px 0;
  }
  #que-elem .que-third table {
    font-size: 18px;
  }
  #que-elem .que-third table tr {
    display: table-row;
    width: auto;
  }
  #que-elem .que-third table tr th,
  #que-elem .que-third table tr td {
    display: table-cell;
    width: auto;
  }
  #que-elem .que-third table tr th {
    width: 160px;
  }
  #que-elem .que-third table tr th span {
    text-align: center;
    font-size: .8em;
    padding: 7px 15px;
  }
  #que-elem .que-third table tr td {
    padding: 10px 0 10px 25px;
  }
  #que-elem .que-third table tr td.col2 label input {
    margin-left: 25px;
    width: calc(100% - 25px);
  }
  .que-foot .box {
    margin: 55px auto 0;
  }
  .que-foot .box h5 {
    font-size: 18px;
  }
  .que-foot .checks {
    font-size: 16px;
  }
  #notice {
    margin: 0 auto 60px;
    padding: 20px;
  }
  #notice p span {
    display: inline-block;
  }
  #notice strong {
    font-size: 28px;
  }
  .foot-body {
    padding: 5% 0 0;
  }
  .foot-foot {
    padding: 5% 0;
  }
  .foot-foot ul li {
    width: 44%;
    margin: 0 3%;
  }
  /* confirm */
  #confirm .notes {
    font-size: 18px;
    margin: 5% 0;
  }
  #confirm #que-elem dl.que-first dt .que {
    margin-left: 41px;
  }
  #confirm #que-elem dl.que-first dd {
    margin-left: 41px;
  }
  #confirm #que-elem .comment {
    font-size: 17px;
    padding: 15px 20px;
  }
  #confirm #que-elem .que-third table tr td {
    padding: 10px 0 10px 25px;
  }
  #finish .notes strong {
    font-size: 1.5em;
  }
}

@media screen and (min-width: 800px) {
  .pc {
    display: block;
  }
  .smt {
    display: none;
  }
  .sec01 h2 {
    font-size: 40px;
  }
  .sec01 p {
    font-size: 24px;
  }
  .sec02 {
    background: url(../img/sec02/bg.png);
    background-size: 1279px auto;
  }
  .sec02 .sec-body p {
    font-size: 20px;
  }
  .sec03 .sec-head p.highlight {
    font-size: 32px;
  }
  .sec04:before {
    background: url(../img/sec04/bg.png);
    background-size: 1279px auto;
  }
  .sec05 h3 {
    font-size: 32px;
  }
  form input,
  form select,
  form textarea {
    font-size: 19px;
  }
  form input,
  form select {
    height: 50px;
  }
  #introduction p.big.error {
    font-size: 20px;
  }
  #question .que-head span {
    font-size: 36px;
  }
  #question .que-body {
    margin: 10% 0 0;
  }
  #question .que-body h4 {
    transform: translateY(-50%);
  }
  #question .que-body h4 span {
    margin-left: 13.5%;
  }
  #question .que-body h4 small {
    display: inline-block;
    position: relative;
    top: -5px;
    margin-left: 1em;
  }
  #que-elem dl dt .que {
    font-size: 26px;
  }
  #que-elem dl dd {
    font-size: 20px;
  }
  #que-elem dl.que-first dt .ico img {
    width: 42px;
  }
  #que-elem dl.que-first dt .que {
    margin-left: 67px;
    background: url(../img/sec05/bg_que.png) repeat-x left bottom;
    background-size: auto 10px;
    padding: 0 5px 15px;
  }
  #que-elem dl.que-first dd {
    margin-left: 67px;
  }
  #que-elem p {
    font-size: 20px;
    margin: 30px 0 20px;
  }
  #que-elem .que-third table {
    font-size: 20px;
  }
  #que-elem .que-third table tr th {
    width: 180px;
  }
  #que-elem .que-third table tr th span {
    padding: 8px 20px;
  }
  #que-elem .que-third table tr td.col2 label input {
    margin-left: 27px;
    width: calc(100% - 27px);
  }
  .que-foot .checks {
    font-size: 17px;
  }
  /* confirm */
  #confirm .notes {
    font-size: 20px;
  }
  #confirm #question .que-body {
    margin: 6% 0 0;
  }
  #confirm #que-elem dl.que-first dt .que {
    margin-left: 47px;
  }
  #confirm #que-elem dl.que-first dd {
    margin-left: 47px;
  }
  #confirm #que-elem .comment {
    font-size: 19px;
  }
}

@media screen and (min-width: 1000px) {
  .sec01 h2 {
    font-size: 50px;
  }
  .sec01 p {
    font-size: 26px;
  }
  .sec02 .sec-body p {
    font-size: 23px;
  }
  .sec02 .sec-foot .illust span {
    transform: scale(1);
  }
  .sec03 .sec-head p.highlight {
    font-size: 43px;
  }
  .sec05 h3 {
    font-size: 40px;
  }
  form input,
  form select,
  form textarea {
    font-size: 21px;
  }
  #question .que-head span {
    font-size: 46px;
  }
  #que-elem dl dt .que {
    font-size: 31px;
  }
  #que-elem dl dd {
    font-size: 23px;
  }
  #que-elem dl.que-first dt .ico img {
    width: 48px;
  }
  #que-elem dl.que-first dt .que {
    margin-left: 73px;
  }
  #que-elem dl.que-first dd {
    margin-left: 73px;
  }
  #que-elem p {
    font-size: 23px;
  }
  #que-elem .que-third table {
    font-size: 23px;
  }
  #que-elem .que-third table tr th {
    width: 200px;
  }
  #que-elem .que-third table tr th span {
    padding: 6px 20px;
  }
  #que-elem .que-third table tr td.col2 label input {
    margin-left: 31px;
    width: calc(100% - 31px);
  }
  .que-foot .checks {
    font-size: 18px;
  }
  .que-foot .checks label:before {
    top: 17px;
  }
  .que-foot .checks label.on:after {
    top: 17px;
  }
  /* confirm */
  #confirm .notes {
    font-size: 22px;
  }
  #confirm #que-elem dl.que-first dt .que {
    margin-left: 53px;
  }
  #confirm #que-elem dl.que-first dd {
    margin-left: 53px;
  }
  #confirm #que-elem .comment {
    font-size: 21px;
  }
}

/* max */
@media screen and (min-width: 1280px) {
  .sec02 {
    margin: 115px 0;
  }
  .sec03 .sec-foot {
    margin: 50px 0 100px;
  }
  .sec04 .sec-body {
    margin: -45px 0 0;
    padding: 0 0 80px;
  }
  .sec05 {
    padding: 70px 0;
  }
  .foot-body {
    padding: 40px 0 0;
  }
  .foot-foot {
    padding: 40px 0;
  }
  #confirm .sec05 {
    padding: 0 0 70px;
  }
}
